<template>
  <div>
    <div class="page_con">
      <div class="main_page">
        <!-- 최근 공지사항 -->
        <component-main-recent-notice :notice-list="noticeList" />

        <div class="box_shadow" v-if="isShowBill === 1 && isImage === 1">
          <div class="box box_build">
            <div class="box_ttl build">
              <img src="@/assets/static/images/img_building.svg" alt="img" />
              <p>
                해당 빌라는 <span>전체 고지서</span>만<br />확인할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
        <div class="box_shadow" v-if="isShowBill === 1 && isImage === 0">
          <p class="inner_ttl">내 관리비</p>
          <div class="card" @click="fnPage">
            <div class="card_top">
              <div class="card_ttl">
                <span class="txt_number txt_type01">{{ unitName }}</span>
                <span class="txt_number txt_type02">{{ month }}월</span
                ><span class="txt_type03">납부요금</span>
              </div>
            </div>
            <div class=""></div>
            <div class="card_bottom">
              <div class="card_con">
                <span class="txt_number txt_type04">{{
                  $Util.formatNumber(thisMonthFee)
                }}</span>
                <span class="txt_type05">원</span>
                <span class="txt_type06">/ 미납포함</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box_shadow">
          <div class="box">
            <div class="box_ttl">
              <img src="@/assets/static/images/img_coin.svg" alt="icon" />
              <p>월별 납부 금액 (최근 6개월)</p>
            </div>
            <div class="box_con">
              <div class="box_list_wrap">
                <div
                  class="box_list flex_box"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <p class="refresh">
                    {{ item.bill.year }}년 {{ item.bill.month }}월
                  </p>
                  <p class="price" v-if="item.isImage === 1">
                    <span>이미지</span>
                  </p>
                  <p class="price refresh" v-else>
                    <span class="number">{{
                      $Util.formatNumber(item.detail.total)
                    }}</span
                    >원
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComponentMainRecentNotice from '@/components/views/main/recent-notice'
import { getCurrentInstance, reactive, toRefs, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  name: 'component-main-user-main',
  description: '회원 빌라요청 승인시 메인화면',
  components: { ComponentMainRecentNotice },
  props: {
    noticeList: {
      type: [Array],
      default: () => {}
    }
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const router = useRouter()

    const userData = computed(() => store.getters['user/getData'])

    const state = reactive({
      unitName: userData.value.unitName,
      month: '',
      thisMonthFee: '',
      items: [],
      isSepBill: 0,
      isShowBill: 0,
      isImage: 0
    })

    watch(state, () => {
      const monthLength = state.month.toString().length
      let month = state.month
      if (monthLength < 2) {
        month = '0' + state.month
      }
      state.month = month
    })

    const fetchThisMonth = async () => {
      try {
        const res = await proxy.$BillSvc.fetchUserPayment()
        if (res.code === 1 && proxy.$Util.isEmpty(res.entity.thisMonth)) {
          state.isSepBill = res.entity.isSepBill
          state.items = res.entity.recent
          state.isImage = res.entity.isImage
        } else {
          state.isSepBill = res.entity.isSepBill
          state.month = res.entity.thisMonth.month
          state.items = res.entity.recent
          state.isShowBill = res.entity.thisMonth.isShowBill
          state.thisMonthFee = res.entity.thisMonth.total
          state.isImage = res.entity.isImage
        }
      } catch (err) {
        console.log(err)
      }
    }

    const fnPage = () => {
      router.push({ path: '/menu/building/status/view', query: { type: 3 } })
    }

    fetchThisMonth()
    return { ...toRefs(state), fetchThisMonth, fnPage }
  }
}
</script>

<style scoped></style>
