<template>
  <div class="box_shadow">
    <div class="box">
      <div class="box_ttl">
        <img src="@/assets/static/images/i_speakerphone.svg" alt="icon" />
        <p>최근 공지 사항</p>
      </div>
      <div class="box_con" v-if="!$Util.isEmpty(noticeList)">
        <div class="box_list_wrap wrap_notice">
          <div
            class="box_list flex_box"
            v-for="(item, index) in noticeList"
            :key="index"
            @click="fnPage(item)"
          >
            <p class="notice_txt">{{ item.board.title }}</p>
            <div class="icon"></div>
          </div>
        </div>
      </div>
      <div class="box_con" v-else>
        <div class="box_list_wrap wrap_notice">
          <div class="box_list flex_box">
            <p class="notice_txt">공지사항이 없습니다.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'component-main-recent-notice',
  description: '메인 최근 공지사항',
  props: {
    noticeList: {
      type: [Array],
      default: () => {}
    }
  },
  setup() {
    const router = useRouter()

    const fnPage = item => {
      router.push({
        path: '/menu/board/view',
        query: { boardNo: item.board.boardNo, type: item.board.type }
      })
    }
    return { fnPage }
  }
}
</script>

<style scoped></style>
