<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <!-- 회원 빌라요청 상태가 요청, 거절, 미신청 일때 -->
        <div class="page_con" v-if="userData.status !== 2">
          <div class="enroll_page">
            <div class="img_box">
              <img src="@/assets/static/images/img_main.svg" alt="img" />
              <p class="txt" v-show="userData.status === 0">
                내 빌라 정보를 등록해 주세요 :)
              </p>
              <span></span>
            </div>
          </div>
          <div class="con_btn" v-show="userData.status === 0">
            <div class="btn_wrap36">
              <button class="btn bo" @click="fnPage">정보 등록하러 가기</button>
            </div>
          </div>
          <div class="notice_box" v-show="userData.status === 1">
            <div class="notice grey">
              <!--grey: 확인중 orange: 거절 red:확인-->
              <p class="txt">
                관리자가 빌라 정보를 확인 중에 있습니다.<br />변경사항이 있으면
                정보 변경을 신청해 주세요.
              </p>
            </div>
          </div>
          <div class="notice_box" v-show="userData.status === 3">
            <div class="notice orange">
              <p class="txt">
                입력된 정보와 동일한 세대주가 없습니다.<br />정보 변경으로 다시
                신청해 주세요.
              </p>
            </div>
          </div>
        </div>
        <!-- 회원 빌라요청 상태 승인시 -->
        <component-main-user-main :notice-list="noticeList" v-else />
      </div>
    </div>
  </main>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ComponentMainUserMain from '@/components/views/main/user-menu'
import { getCurrentInstance, reactive, toRefs } from 'vue'
export default {
  name: 'main-index',
  components: { ComponentMainUserMain },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { proxy } = getCurrentInstance()

    const userData = store.getters['user/getData']

    const state = reactive({
      params: {
        page: 1,
        limit: 2,
        type: 1
      },
      noticeList: []
    })

    const fnNoticeList = async () => {
      state.params.buildingNo = userData.buildingNo
      const res = await proxy.$BoardSvc.fetchUserBoardList(state.params)
      if (res.code === 1) {
        state.noticeList = res.entity.list
      }
    }

    const fnPage = () => {
      router.push({ path: '/menu/building/register' })
    }

    fnNoticeList()
    return { fnPage, userData, ...toRefs(state), fnNoticeList }
  }
}
</script>

<style scoped></style>
